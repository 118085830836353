import React, { useState } from "react";
import Slider from "react-slick";
import "react-image-lightbox/style.css";
import "../styles/ProductCard.scss";
import Lightbox from "react-image-lightbox";

const ProductCard = ({ product, categories }) => {
  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    return category ? category.name : "";
  };

  const messageText = `¡Hola! Estoy interesada en el producto ${
    product.title
  }. ${selectedColor ? selectedColor.name : ""}. ${
    selectedSize ? `Tamaño ${selectedSize}.` : ""
  }`;

  const handleImageClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="product-card">
      {product.priceDiscount && <div className="ribbon">En oferta</div>}
      <div className="product-title__container">
        <h3 className="product-title">{product.title}</h3>
        <span className="product-category">
          {getCategoryName(product.category)}
        </span>
      </div>
      <div className="product-image-slider">
        <div>
          {selectedColor && selectedColor.images?.length > 1 && (
            <Slider {...sliderSettings}>
              {selectedColor.images.length > 1 &&
                selectedColor.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={
                        "https://cllanuteqoprdldvmdqk.supabase.co/storage/v1/object/public/product-images/" +
                        image
                      }
                      alt={product.title}
                      className="product-image"
                      onClick={() => handleImageClick(index)}
                    />
                    <div
                      className="product-image__placeholder"
                      onClick={() => handleImageClick(index)}
                    >
                      + Ampliar foto
                    </div>
                  </div>
                ))}
            </Slider>
          )}
          {selectedColor && selectedColor.images?.length < 2 && (
            <div>
              <img
                src={
                  "https://cllanuteqoprdldvmdqk.supabase.co/storage/v1/object/public/product-images/" +
                  selectedColor.images[0]
                }
                alt={product.title}
                className="product-image"
                onClick={() => handleImageClick(0)}
              />
              <div
                className="product-image__placeholder"
                onClick={() => handleImageClick(0)}
              >
                + Ampliar foto
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="product-sizes">
        {selectedColor &&
          selectedColor.sizes?.map((size) => (
            <div
              key={size}
              className="size"
              onClick={() => setSelectedSize(size)}
            >
              {size}
            </div>
          ))}
      </div>
      <div className="product-colors">
        {product.colors.map((color) => (
          <div
            key={color.name}
            className="color"
            style={{ backgroundColor: color.hex }}
            onClick={() => setSelectedColor(color)}
          ></div>
        ))}
      </div>
      {!product.priceDiscount && (
        <p className="product-price">${Number(product.price).toFixed(2)}</p>
      )}
      {product.priceDiscount && (
        <p className="product-price">
          <span className="product-price__discount">
            ${Number(product.price).toFixed(2)}
          </span>{" "}
          ${Number(product.priceDiscount).toFixed(2)}
        </p>
      )}
      <a
        href={`https://wa.me/+584241470218/?text=${messageText}`}
        className="product-button"
      >
        ¡Me interesa!
      </a>
      {isOpen && (
        <Lightbox
          mainSrc={
            "https://cllanuteqoprdldvmdqk.supabase.co/storage/v1/object/public/product-images/" +
            selectedColor.images[photoIndex]
          }
          nextSrc={
            "https://cllanuteqoprdldvmdqk.supabase.co/storage/v1/object/public/product-images/" +
            selectedColor.images[(photoIndex + 1) % selectedColor.images.length]
          }
          prevSrc={
            "https://cllanuteqoprdldvmdqk.supabase.co/storage/v1/object/public/product-images/" +
            selectedColor.images[
              (photoIndex + selectedColor.images.length - 1) %
                selectedColor.images.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + selectedColor.images.length - 1) %
                selectedColor.images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % selectedColor.images.length)
          }
        />
      )}
    </div>
  );
};

export default ProductCard;
