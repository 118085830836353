import React, { useState } from "react";
import "../styles/FilterMenu.scss";

const FilterMenu = ({
  isOpen,
  categories,
  colors,
  sizes,
  applyFilters,
  closeMenu,
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);

  const toggleSelection = (array, setArray, value) => {
    if (array.includes(value)) {
      setArray(array.filter((item) => item !== value));
    } else {
      setArray([...array, value]);
    }
  };

  const applyFilter = () => {
    applyFilters({
      categories: selectedCategories,
      colors: selectedColors,
      sizes: selectedSizes,
    });
  };

  const cleanFilters = () => {
    setSelectedCategories([]);
    setSelectedColors([]);
    setSelectedSizes([]);
  };

  return (
    <div className={`filter-menu ${isOpen ? "open" : ""}`}>
      <button className="close-button" onClick={closeMenu}>
        ×
      </button>
      <h2>Filtros</h2>
      <div className="filter-section">
        <h3>Categorías</h3>
        {categories.map((category) => (
          <div key={category.id} className="filter-option">
            <input
              type="checkbox"
              id={`category-${category.id}`}
              onChange={() =>
                toggleSelection(
                  selectedCategories,
                  setSelectedCategories,
                  category.id
                )
              }
              checked={selectedCategories.includes(category.id)}
            />
            <label htmlFor={`category-${category.id}`}>{category.name}</label>
          </div>
        ))}
      </div>
      {colors && colors.length > 0 && colors[0] !== null && (
        <div className="filter-section">
          <h3>Colores</h3>
          {colors.map((color) => (
            <div key={color} className="filter-option">
              <input
                type="checkbox"
                id={`color-${color}`}
                onChange={() =>
                  toggleSelection(selectedColors, setSelectedColors, color)
                }
                checked={selectedColors.includes(color)}
              />
              <label htmlFor={`color-${color}`}>{color}</label>
            </div>
          ))}
        </div>
      )}
      {false && sizes && sizes.length > 0 && (
        <div className="filter-section">
          <h3>Tallas</h3>
          {sizes.map((size) => (
            <div key={size} className="filter-option">
              <input
                type="checkbox"
                id={`size-${size}`}
                onChange={() =>
                  toggleSelection(selectedSizes, setSelectedSizes, size)
                }
                checked={selectedSizes.includes(size)}
              />
              <label htmlFor={`size-${size}`}>{size}</label>
            </div>
          ))}
        </div>
      )}
      <div className="buttons-filters-container">
        <button className="apply-filters-button" onClick={applyFilter}>
          Aplicar Filtros
        </button>
        <button className="clean-filters-button" onClick={cleanFilters}>
          Limpiar filtros
        </button>
      </div>
    </div>
  );
};

export default FilterMenu;
