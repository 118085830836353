import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./components/Header";
import FilterMenu from "./components/FilterMenu";
import ProductList from "./components/ProductList";
import "./styles/App.scss";

const App = () => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isSingleColumnView, setIsSingleColumnView] = useState(false);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "https://cllanuteqoprdldvmdqk.supabase.co/functions/v1/get-products"
      );
      setProducts(response.data.data);
      setFilteredProducts(response.data.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://cllanuteqoprdldvmdqk.supabase.co/functions/v1/get-categories"
      );
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    // Update colors and sizes arrays only when products state changes
    const colorSet = new Set();
    const sizeSet = new Set();
    products.forEach((product) => {
      product.colors.forEach((color) => {
        colorSet.add(color.name.trim()); // Clean whitespace before adding to set
        color.sizes?.forEach((size) => sizeSet.add(size));
      });
    });
    setColors(Array.from(colorSet));
    setSizes(Array.from(sizeSet));
  }, [products]); // Watch for changes in products state to update colors and sizes

  const applyFilters = (filters) => {
    const { categories, colors, sizes } = filters;

    const filtered = products.filter((product) => {
      const categoryMatch =
        categories.length === 0 || categories.includes(product.category);
      const colorMatch =
        colors.length === 0 ||
        product.colors?.some((color) => colors.includes(color.name.trim())); // Clean whitespace before matching
      const sizeMatch =
        sizes.length === 0 ||
        product.sizes?.some((size) => sizes.includes(size));

      return categoryMatch && colorMatch && sizeMatch;
    });

    setFilteredProducts(filtered);
    setFilterMenuOpen(false);
  };

  return (
    <div className="App">
      <Header />
      {false && (
        <div className="banner">
          <img
            src="https://d13xymm0hzzbsd.cloudfront.net/landing_new/168356616031101.jpg"
            alt="Banner"
          />
        </div>
      )}
      <div className="buttons-container">
        <button
          className="filter-button"
          onClick={() => setFilterMenuOpen(!filterMenuOpen)}
        >
          <i className="fas fa-filter"></i> Filtrar
        </button>
        <button
          className="view-button"
          onClick={() => setIsSingleColumnView(!isSingleColumnView)}
        >
          <i className="fas fa-expand"></i> Cambiar vista
        </button>
      </div>
      <FilterMenu
        isOpen={filterMenuOpen}
        categories={categories}
        colors={colors} // Use colors state here
        sizes={sizes}
        applyFilters={applyFilters}
        closeMenu={() => setFilterMenuOpen(false)}
      />
      <ProductList
        products={filteredProducts}
        isSingleColumnView={isSingleColumnView}
        categories={categories}
      />
    </div>
  );
};

export default App;
