import React from "react";
import logo from "../assets/logo.png";
import "../styles/Header.scss";

const Header = () => (
  <header className="header">
    <img src={logo} alt="Sienna" className="logo" />
    <a
      href="https://wa.me/message/JWPJ3JSWOUCQF1"
      style={{ textDecoration: "none" }}
    >
      <button className="whatsapp-button" onClick={() => {}}>
        <i className="fab fa-whatsapp"></i>
      </button>
    </a>
  </header>
);

export default Header;
