import React from "react";
import ProductCard from "./ProductCard";
import "../styles/ProductList.scss";

const ProductList = ({ products, categories, isSingleColumnView }) => {
  return (
    <div
      className={`product-list ${isSingleColumnView ? "single-column" : ""}`}
    >
      {products.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          categories={categories}
        />
      ))}
    </div>
  );
};

export default ProductList;
